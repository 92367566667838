const programDetails = [
  {
      id: 1,
      title: <a href='Simulation1'>Simulation1 - Stakeholder Management</a>,
      info: "Real time simulation is related to a Major Natural Resources (Mining) Project dealing with Responsible leadership/Managing 12 major Stakeholders",
    },
    {
      id: 2,
      title: <a href='Simulation2'>Simulation2 - Project Leadership</a>,
      info: "Real time simulation deals with a Major (Stadium) Construction Project be rebuilt ideally in 24 months in 50 Million pounds",
    },

];
export default programDetails;