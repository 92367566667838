import React from 'react'

import Footer from './Footer'
import Navbar from './navbar'
import privacyComp from './Pages/privacyComp'

const Privacypolicy = () => {
    return (
        <>
            <Navbar />
            <section id="home-sec">
                <div class="overlay text-center">
                    <h1 >Privacy policy</h1><br />
                    <hr class="hr-set" />

                    <p>International Project Management Team Excellence Award (I-PMTEA) </p>
                </div>

            </section>


            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" >
                        <div class="overlay text-center">
                            <h3><strong>Privacy policy</strong></h3>
                            
</div>
<div><p className="main-hero-para">ipmtea (“IPMTEA.com,” “we,” or “us”) is committed to protecting your privacy, we take data protection and privacy very seriously. This Privacy Policy (“Policy”) describes how IPMTEA.com collects, uses, shares and secures the personal information you provide when you visit the Websites and Mobile Apps owned and operated by IPMTEA.com (listed below), and when you use our Service(s). It also describes your choices regarding use, access and correction of your personal information. The use of information collected through our Service(s) shall be limited to the purpose of providing the service for which you have engaged IPMTEA.com</p></div>


<p className="main-hero-para"><strong>Notice to End Users</strong><br /><br />
In some cases an administrator may create an account in IPMTEA.com on the behalf of a “End-User” and may provide his/her information, including Personal Information. In such cases the administrator who may be your Employer or a cepm Pvt. Ltd. Authorised Training partner (ATP) is our “Customer.” We collect Information under the direction of our customers and have no direct relationship with the End-User whose personal data we process.

A End-user’s use of the IPMTEA.com platform may be subject to the Customer’s policies, if any. Our Customer must have the authority to act on behalf of the End-User and to consent to the collection and use of their Personal Information as described in this Privacy Policy.

If you are an End-user using our platform, please direct your privacy inquiries to your administrator. IPMTEA.com is not responsible for the privacy or security practices of our customers, which may differ from those set forth in this privacy statement. IPMTEA.com does not send personalized advertisement or Marketing communication to end users.</p>


<p className="main-hero-para"><strong>International Transfer</strong><br /><br />
Personal data collected by IPMTEA.com may be stored and processed in your region, and in any other country where IPMTEA.com or its affiliates, data sub-processors operate facilities. To facilitate IPMTEA.com global operations, we may transfer and access such information from around the world.

Currently, IPMTEA.com and its data sub processors have data centers in India.

The data we hold may also be processed by employees operating in countries listed above, Such staff may be engaged in, among other things, product development and customer and technical support. The data protection and other laws of these countries might not be as comprehensive as those in your country. By submitting your data and/or using Our Services, You consent to the transfer, storing, and processing of Your data in these countries.
</p>



<p className="main-hero-para"><strong>Use of your Personal Information and Other Data</strong><br /><br />
IPMTEA.com uses this Privacy Policy to govern the use of personal information you provide to us. We use your personal information solely to provide our services to you. We also use your Personal Identifiable Information (PII) together with other Personally Non-Identifiable Information (non - PII) to help us better understand our users and to improve the content and functionality of our site.
</p>


<p className="main-hero-para"><strong>Personal Identifiable Information</strong><br /><br />
We may collect the following personal information from you in the following situations

When you visit our website or mobile apps and voluntarily provide the following information via one of our contact forms, via a chat or phone session, or as part of a purchase of one of our courses or register on our site:

Contact Information,such as name, email address, mailing address, phone number, IP

Information about your business, such as company name, company size, business type

Billing Information, such as credit card number and billing address;

Note : All payment transactions are processed through secure payment gateway providers. We do not store any card information (other than the last 4 digits of your card) in our servers.

When you use our Learning Management System (*.lms.IPMTEA.com.com), mobile applications, our support system or respond to our surveys:

Contact Information, such as name, email address, mailing address, IP address, geographic location, or phone number;

Unique Identifiers, such as username, or password;

Name and e-mail address when you provide feedback from the Service(s)

When you use our community website or blogs

Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.

</p>


<p className="main-hero-para"><strong>Non-Personal Identifiable Data:</strong><br /><br />
We capture some Non Identifiable data in our logs. The data in the logs includes<br />
Device type<br />
browser type<br />
language preference,<br />
time zone,<br />
screen size<br />
referring / exit pages
</p>

<p className="main-hero-para"><strong>Use</strong><br /><br />
We may use the personal information collected from within the Websites or when you use the Service(s) to: Provide you with the Service(s). Send you communication from the Service(s) Assess the needs of your business to determine or suggest suitable products. Send you requested product or service information. Respond to customer service requests. Administer your account. Send you promotional and marketing communications. Facilitate your transactions with other users when you use our Service(s). Perform statistical analyses of user behaviour and characteristics at an aggregate level in order to measure interest in and use of the various areas of the site. When you provide us with personal information about your contacts we will only use this information for the specific reason for which it is provided.
</p>


<p className="main-hero-para"><strong>Share</strong><br /><br />
If your subscription was provided by your employer or a IPMTEA.com Authorised training partner, we will share information, including personal information with them.

We may transfer personal information to our Group Companies and sub-contractors that help us provide our Service(s). Transfers to subsequent third parties are covered by the service agreements with our data-processors. We reserve the right to disclose your personal data as required by applicable law, in response to lawful requests by public authorities, including meeting national security or law enforcement requirements and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or other legal process served on us.

In the event IPMTEA.com goes through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account and personal data will likely be among the assets transferred. You will be notified via email and via a prominent notice on our Websites of any such change in ownership or control. We may also disclose your personal information to any other third party with your prior consent.
</p>

<p className="main-hero-para"><strong>Data from Third Parties</strong><br /><br />
We also obtain data from third parties. We protect data obtained from third parties according to the practices described in this statement, plus any additional restrictions imposed by the source of the data. These third-party sources vary over time, but have included:

Data brokers from which we purchase demographic data to supplement the data we collect. Social networks when you grant permission to access your data on one or more networks. Existing or past learners who participate in our referral program Service providers that help us determine a location based on your IP address in order to customize certain products to your location. Partners with which we offer co-branded services or engage in joint marketing activities, and Publicly-available sources such as open government databases or other data in the public domain.

We may receive Personal Information about you from other sources like postal mail, telephone or fax. We may add this information to the information we have already collected from you via the Site in order to improve the products and services we provide.
</p>

<p className="main-hero-para"><strong>Correction, Removal or Export of Personal Information</strong><br /><br />
IPMTEA.com acknowledges that you have the right to access, edit and delete your personal information at any time. Upon request, we will provide you with reasonable access to the personal information we collect about you. You will have the opportunity to correct, update, modify or delete this information as follows:

If you have independently signed up with IPMTEA.com. You may access, update or export your Personal Information included in your online IPMTEA.com account at any time by logging into your account and going to your profile. If you are using an enterprise-provided account (IPMTEA.com Authorised Training Partner or your Employer)

</p>

<p className="main-hero-para"><strong>Exceptions in Deletion:</strong><br /><br />
If you request access to your personal information and discover that that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes. We aim to maintain our services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our services, we may not immediately delete residual copies from our active servers or backup systems

</p>
<p className="main-hero-para"><strong>Exception in Data Portability:</strong><br /><br />
There may be limits to the amount of information we can practically provide. For example, we may limit an individual’s access to personal information where the burden or expense of providing access would be disproportionate to the risks to the individual’s privacy or where doing so would violate others’ rights.
</p>

<p className="main-hero-para"><strong>Exception in Data Correction:</strong><br /><br />
Even after updates to your Personal Information, we may maintain and store older versions of such information within our databases, access logs and other records in order to comply with other routine data audits.
</p>

<p className="main-hero-para"><strong>Cookies and Other Tracking Technologies</strong><br /><br />
IPMTEA.com and our third-party partners, such as our advertising and analytics partners, (listed here) use cookies and other tracking technologies (e.g., web beacons, device identifiers and pixels) to provide functionality and to recognize you across different Services and devices.

The types of cookies used by IPMTEA.com includes
</p>
<p className="main-hero-para"><strong>Analytics & Web-beacons</strong><br /><br />
IPMTEA.com uses third party software for analytics. All metrics information collected from your usage of the Service(s) shall be transmitted to our analytics provider. This information is then used to evaluate how users use our platform, and to compile statistical reports on user activity for us. We further use the same statistical analytics tool to track or to collect your Personally Identifiable Information (PII). We will use this information in order to maintain, enhance, or add to the functionality of the Service(s) and to personalize the experience for you. Aggregated information and analysis may be made public where desired by IPMTEA.com.

We and our third party advertising partners use technologies such as web beacons in analyzing trends, administering the website, tracking users’ movements around the site, and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual and aggregated basis.
</p>

<p className="main-hero-para"><strong>Do Not Track</strong><br /><br />
Currently, various browsers — including Internet Explorer, Firefox, and Safari — offer a “do not track” or “DNT” option that relies on a technology known as a DNT header, which sends a signal to Web sites’ visited by the user about the user's browser DNT preference setting. IPMTEA.com does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. IPMTEA.com takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.
</p>


<p className="main-hero-para"><strong>Links to Third Party Sites</strong><br /><br />
Our Websites contain links to other websites that are not owned or controlled by IPMTEA.com. Please be aware that we are not responsible for the privacy practices of such other websites or third parties. We encourage you to be aware when you leave our Websites and to read the privacy policies of each and every website that collects personal information.
</p>

<p className="main-hero-para"><strong>Public Forums</strong><br /><br />
Our Websites offer publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, reach out to us. In some cases, we may not be able to remove your personal information, in which case we will let you know that we are unable to do so and why.

IPMTEA.com may provide bulletin boards, blogs, or community threads on the Company’s Web sites. Any personal information you choose to submit in such a forum may be read, collected, or used by others who visit these forums, and may be used to send you unsolicited messages. IPMTEA.com is not responsible for the personal information you choose to submit in these forums.

Remember that when you share information publicly, it may be indexable by search engines.
</p>
                        
 <p className="main-hero-para"><strong>Communications from the Websites</strong><br /><br />    

 We may use your email address to send our newsletters and/or marketing communications. If you no longer wish to receive these communications, you can opt out by following the instructions contained in the emails you receive or by contacting us at

We will send you announcements related to the Service(s) on occasions when it is necessary to do so. For instance, if our Services) is temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of communications which are not promotional in nature. If you do not wish to receive them, you may delete your Account.
 </p>              
                        
 <p className="main-hero-para"><strong>Children's Personal Information</strong><br /><br />                     
 IPMTEA.com does not knowingly collect any personal information from children under the age of 16. If you are under the age of 16, please do not submit any personal information through our Websites or Service(s). We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide personal information through our Service(s) or Websites without their permission. If you have reason to believe that a child under the age of 16 has provided personal information to us through our Websites or Service(s), please contact us and we will endeavor to delete that information and terminate the child's account from our databases.               
</p>     

<p className="main-hero-para"><strong>Amendments</strong><br /><br />
IPMTEA.com has the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. Your continued use of this Websites or the Service(s) following the posting of any amendment, modification, or change to this Policy shall constitute your acceptance of the amendments to this Policy. You can choose to discontinue use of the Websites or Service(s), if you do not accept the terms of this Policy, or any modified version of this Policy.

</p>
                        
<p className="main-hero-para"><strong>Contacting IPMTEA.com</strong><br /><br />  
f you have any questions about this privacy policy or your dealings with the CEPM Pvt. Ltd., you can reach out to us using the IPMTEA.com Help and Support page.

You can also reach out to us through Email at or postal mail at:

CEPM

A-48, Sector 5, NOIDA 201301, INDIA<br />

+91-120-2420444 / 2420463<br />

+91-9999684621<br />

Fax: +91-120-2421482, +91-11-26273481<br />

info@cepm.com

</p>               
                        
                        
                        
                        
                        </div>

                    </div>

               </div>
            </section>























            <Footer />

        </>
    )
}

export default Privacypolicy
