const workapi2 = [
  {
    id: 1,
    logo: "images/IPMTEA1.png",
    title: "Winning Team",
    info: "2024 International Winner's Trophy",
  },
  {
    id: 2,
    logo: "images/IPMTEA1.png",
    title: "Runner-Up Team ",
    info: "2024 International Runner-Up Trophy",
  },
  {
    id: 3,
    logo: "images/sendRunner.jpg",
    title: "Second Runner-Up Team",
    info: "CEPM Trophy",
  },
  {
    id: 4,
    logo: "images/scope-trophy.jpg",
    title: "Best CPSU Team",
    info: "SCOPE Trophy",
  }, 
  {
    id: 5,
    logo: "images/iocDirect.jpg",
    title: "Best IOC Directorate Team",
    info: "",
  },

];


export default workapi2;