import React from 'react';
import Carousel from "react-elastic-carousel";
import Item from './Item';




const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

const Client = () => {
    return (
        <>
            
            <h1 style={{ textAlign: "center" }}>349 Participants in 79 Teams from 26 Companies in National/International PMTEA in 2018 to 2021</h1>
      <div className="App1">
        <Carousel breakPoints={breakPoints}>
          <Item><img src={"./images/ntpc1.jpg"} alt="NTPC Logo" /></Item>
          <Item><img src={"./images/ncl2.jpg"} alt="NCL Logo" /></Item>
          <Item><img src={"./images/indianOil3.jpg"} alt="Indian Oil Logo" /></Item>
          <Item><img src={"./images/siemens04.jpg"} alt="Siemens Logo" /></Item>
          <Item><img src={"./images/tatamotors5.jpg"} alt="Tata Motors Logo" /></Item>
          <Item><img src={"./images/nbcc6.jpg"} alt="nbcc Logo" /></Item>
          <Item><img src={"./images/igl08.jpg"} alt="igl Logo" /></Item>
          <Item><img src={"./images/heml010.jpg"} alt="heml Logo" /></Item>
          <Item><img src={"./images/gail09.jpg"} alt="gail Logo" /></Item>
          <Item><img src={"./images/dmicdc11.jpg"} alt="Dmicdc Logo" /></Item>

          <Item><img src={"./images/bharatDynamics012.jpg"} alt="bharat Dynamics012 Logo" /></Item>
          <Item><img src={"./images/hfcl013.jpg"} alt="hfcl013 Logo" /></Item>
          <Item><img src={"./images/nitiaayog13.jpg"} alt="Niti Aayog Logo" /></Item>
          <Item><img src={"./images/ge16.jpg"} alt="GE Logo" /></Item>
          <Item><img src={"./images/iitDelhi017.jpg"} alt="IIT Delhi Logo" /></Item>
          <Item><img src={"./images/brahMos20.jpg"} alt="brahMos Logo" /></Item>
          <Item><img src={"./images/mcllogo19.jpg"} alt="mcl Logo" /></Item>
          <Item><img src={"./images/BEML.png"} alt="BEML Logo" /></Item>
          <Item><img src={"./images/BharatPetroleum.png"} alt="Bharat Petroleum" /></Item>
          <Item><img src={"./images/CPCL.png"} alt="CPCL Logo" /></Item>
          <Item><img src={"./images/DVC.png"} alt="DVC Logo" /></Item>
          <Item><img src={"./images/ECL.png"} alt="ECL Logo" /></Item>
          <Item><img src={"./images/TechnipEnergies.png"} alt="Technip Energies Logo" /></Item>
         
          <Item><img src={"./images/notehwesterUniversity07.jpg"} alt="NotehWestern University Logo" /></Item>
    
        </Carousel>
      </div>
       
 
        </>
    )
}

export default Client
