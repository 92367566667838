import React from 'react'
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>

      <footer>
        <div className="container">
          <div className="row">


            <div className="col-sm-4">
              <figure>
                <img
                  src="./images/cepmlogo.jpg"
                  height="auto"
                  width="100"
                  alt="CEPM Logo"
                  className="img-fluid"

                />
              </figure>
              <h2>Centre for Excellence in Project Management Pvt. Ltd. </h2>
              <ul>
                <li>
                  <li>
                    <p></p>
                  </li>
                  <li>Address</li>
                  <h2> Ruchira Jain</h2> (First Certified Senior Project Manager from India) <br />
                  <h2> Managing Director</h2>
                  Centre for Excellence in Project Management (P) Ltd. (CEPM)<br />
                  Cell : +91 9999684622, +91 9999684621 <br />
                  {/* Cell : +91 9999684622 | Tel : +0120-2420444 <br /> */}
                  Email: rjain@cepm.com | Web: www.cepm.com

                </li>
                <li>
                  <a href="#"></a>
                </li>

              </ul>
            </div>

            <div className="col-sm-4">
              <div class="overlay text-center">
                <h1 class="white"> in association with</h1>
              </div>
              {/*<li class="nav-item">
                <NavLink class="nav-link white" to="/Privacypolicy">
                  <center>Privacy policy</center>
                </NavLink>

              </li>*/}


              {/*<div class="overlay text-center">
                      <h2 > in association with</h2>
                    </div>
                    <ul>
                      <li>
                        
                      </li>
                      <li class="nav-item">
                        <NavLink class="nav-link" to="/Privacypolicy">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy policy
                      </NavLink>
                      </li>
                      <li>
                        
                      </li>
                      <li>
                        
                      </li>
                      </ul>*/}

            </div>



            <div className="col-sm-4">
              <figure>
                <img
                  src="./images/i2p2mlogo.jpg"
                  height="auto"
                  width="140"
                  alt="CEPM Logo"
                  className="img-fluid"

                />
              </figure>
              <h2>International Institute of Projects and Program Management (i2P2M)  </h2>

              <ul>
                <li>
                  <li>
                    <p></p>
                  </li>
                  <li>Address</li>
                  <h2> Ashish Sharma</h2>
                  <h2> I-PMTEA Coordinator</h2>
                  International Institute of Projects & Program Management (i2P2M)<br />
                  Cell : +91 9999684621, +91 9999684623  <br />
                  {/* Cell : +91 9999684621 | Tel : +0120-2420463 <br /> */}
                  Email: chairmanoffice@i2p2m.com | Web: www.i2p2m.com

                </li>
              </ul>

              {/* <ul>
                <li>
                  <li>
                    <p></p>
                  </li>
                  <li>Address</li>
                  <h2> Shikha Khandelwal</h2>(B.Ed, M.Com, MBA)  <br />
                  <h2> IPMTEA Coordinator</h2>
                  International Institute of Projects & Program Management (i2P2M)<br />
                  Cell : +91 9999684621 | Tel : +0120-2420463 <br />
                  Email: shikha@i2p2m.com | Web: www.i2p2m.com

                </li>
              </ul> */}


              <div className="row">
                <div className="col-3 mx-auto">
                  <i class="fab fa-facebook-f fontawesome-style"></i>
                </div>
                <div className="col-3 mx-auto">
                  <a
                    href="https://www.instagram.com/"
                    target="_thapa">
                    <i class="fab fa-instagram fontawesome-style"></i>
                  </a>
                </div>
                <div className="col-3 mx-auto">
                  <i class="fab fa-youtube fontawesome-style"></i>
                </div>
                <div className="col-3 mx-auto">
                  <i class="fab fa-twitter fontawesome-style"></i>
                </div>
              </div>
            </div>

            <hr />
              <li class="nav-item">
                <NavLink class="nav-link white" to="#">
                  <center>Privacy policy</center>
                </NavLink>
              </li>
          
              <p className="main-hero-para text-center w-100">
                Copyright @ 2021 CEPM. All rights reserved.
              </p>
            
            {/*<div className="mt-5">
              <p className="main-hero-para text-center w-100">
                Copyright @ 2021 CEPM. All rights reserved.
              </p>
            </div>*/}

          </div>
        </div>{/*--container--*/}
      </footer>

    </>
  )
}

export default Footer
