import React from 'react'
import Navbar from "../navbar";
import Footer from "../Footer";
import AboutComp from "../AboutComp";


const About = () => {
    return (
        <>
      <Navbar />
<AboutComp />
      <Footer />  
        </>
    )
}

export default About
