import React, {useState} from 'react';
import workapi2 from './API/workApi2';

const Trophy2 = () => {
  
  const [workData, setWorkData] = useState(workapi2);
  //console.log(workData);
  
    return (
       <>
            
<section>
<div className="work-container container">
<h1 className="main-heading text-center">Trophies</h1>
<div className="row">
{workData.map((curElem) => {
    const {id, logo, title, info} = curElem;

    return(
        <>
<div class="trophy2 work-container-subdiv">{/*col-12 col-lg-3 text-center work-container-subdiv*/}

<i><img src={logo} alt="trophy" /></i>
<h2 className="sub-heading">{title}</h2>
<p className="my-main-hero-para w-100"><strong>{info}</strong></p>

        </div>
        </>
    );
})}

</div>
</div>
</section>

        </>
    );
}

export default Trophy2
