import React from 'react'

const Error = () => {
    return (
        <>
            Error Page..!
        </>
    )
}

export default Error
