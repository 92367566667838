import React from 'react';

 const Header = () => {
  return (
    <>
      <header>
        <section className="container no-padding-top">{/*main-hero-container*/}
          <div className="row">
            <div className="col-6 col-lg-6 header-left-side d-flex justify-content-center flex-column align-items-start ">
              <h3 className="my-display-3">
                <span class="maroon">International</span>-Project Management Team Excellence Award (<span class="maroon">I</span>-PMTEA 2024)
              </h3>

              <h2><strong>Physical Mode : </strong></h2><p class="p-font">Participating Teams from India, <span class="maroon">14-15 November 2024</span> at Hotel Le Meridien, New Delhi
              </p>
              <h2><strong>Virtual Mode : </strong></h2><p class="p-font">Participating Teams from outside India, <span class="maroon">13-14 November 2024</span></p>

              <p className="main-hero-para">
                <h3>
                  Participate...show your mettle...
                  and win the 100% objective competition</h3>
              </p>
              {/* <h3>Get more information </h3> */}
              <div className="input-group mt-1">
                {/* <input
              type="text"
              className="rounded-pill w-50  w-lg-75 me-3 p-2 form-control-text"
              placeholder="Enter Your Email"
            />*/}
                <div><a href="https://www.cepm.com/download/I-PMTEA-2024.pdf" class="btn btn-style1" target="_blank">Download Brochure</a></div>{/*className="input-group-button"removed by tarun*/}
                </div>                
            </div>
            {/*  --------------- main header right side--------------  */}
            <div className="col-6 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-herosection-images">
              <img
                src="./images/ipmtea.jpg" alt="heroimg" className="img-fluid" />
              {/* <img
              src="./images/hero1.jpg"
              alt="heroimg"
              className="img-fluid"
            />
            <img
              src="./images/hero4.jpg"
              alt="heroimg4"
              className="img-fluid main-hero-img2"
            /> */}
            </div>
          </div>
        </section>
      </header>
    </>
  )
}

export default Header
