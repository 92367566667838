const winnerApi = [
    {
        id: 1,
        logo: "images/Winner-Trophy.jpg",
        title: "WINNER TROPHY",
        info: "",
      },

      {
        id: 2,
        logo: "images/Runner-Up-Trophy.jpg",
        title: "RUNNER-UP TROPHY",
        info: "",
      },

      {
        id: 3,
        logo: "images/Runner-Up-2-Trophy.jpg",
        title: "2 Runner-up Trophy & Best IOC Directorate Trophy",
        info: "",
      },

];

export default winnerApi;