import React from 'react'
import Navbar from '../navbar';
import Footer from '../Footer';
import ContComp from '../ContComp';

const Contact = () => {
  return (
    <>
      <Navbar />
     
      <ContComp />

      <Footer />
    </>
  )
}

export default Contact
